import * as yup from "yup";
import { Form, Input, SubmitButton, FormItem } from "formik-antd";
import { message, Modal } from "antd";
import { Formik } from "formik";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

function ReportDNC() {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(true);

  async function onSubmit(values, formOptions) {
    const axios = require("axios").default;
    // const endpoint = `https://localhost:44343/api/CrowdSourceDncs`;
    const endpoint = `https://tcpaportalapi20211224001646.azurewebsites.net/api/CrowdSourceDncs`;

    try {
      values.count = 0;
      await axios.post(endpoint, values);
      message.success("Successfully Reported");
    } catch (error) {
      message.error(error);
    }

    navigate(-1);
  }

  const handleCancel = () => {
    navigate(-1);
    setModalVisible(false);
  };

  const validationSchema = yup.object({
    phoneNumber: yup
      .string()
      .required("Phone Number is Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    justification: yup.string().required("justification is Required"),
  });

  return (
    <Fragment>
      <Modal
        maskClosable={false}
        closable={true}
        visible={modalVisible}
        onCancel={handleCancel}
        footer={<></>}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <h1
          style={{
            background: "#f08080",
            textAlign: "center",
            border: "3px solid  black",
            fontFamily: "monospace",
          }}
        >
          Report a DNC!
        </h1>
        <Formik
          initialValues={{}}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          render={() => (
            <Form className="form">
              <FormItem
                className="form-item"
                name="phoneNumber"
                label="Phone Number"
                required
              >
                <Input name="phoneNumber" placeholder="Phone Number" />
              </FormItem>

              <FormItem
                className="form-item"
                name="justification"
                label="Justification"
                required
              >
                <Input.TextArea
                  name="justification"
                  placeholder="Justification"
                />
              </FormItem>

              <SubmitButton>Report</SubmitButton>
            </Form>
          )}
        />
      </Modal>
    </Fragment>
  );
}
export default ReportDNC;
