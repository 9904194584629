import axios from "axios";
import { useEffect, useState } from "react";
import ApiEndpointConstants from "../../constants/ApiEndpointConstants";

function LitigatorsName({ prefixLabel, name, responseMessage }) {
  const [isLoading, setIsLoading] = useState(true);

  const [response, setResponse] = useState(true);
  const [error, setError] = useState("");

  function getLitigatorsResponseMessage(response) {
    if ("match" in response.data) {
      if (response?.data?.results["is_bad_number"]) {
        let name = response?.data?.results["name"];
        let status = response?.data?.results["status"];
        return `${name} - ${status} `;
        // return <span style={{ color: "red" }}>{`${name} - ${status} `}</span>;
      }
      return `No Cases Found by ${name}`;
    }
    return `No Cases Found by ${name}`;
  }

  async function fetchData() {
    try {
      const response = await axios.get(
        ApiEndpointConstants.LITIGATORS_NAME_API_ENDPOINT + name,
        {
          headers: {
            "Content-Type": "application/json",
          },
          auth: {
            username: ApiEndpointConstants.LITIGATORS_API_USER,
            password: ApiEndpointConstants.LITIGATORS_API_PASSWORD,
          },
        }
      );

      setResponse(response);

      responseMessage(getLitigatorsResponseMessage(response));
    } catch (error) {
      setError(`Error : ${JSON.stringify(error.data)} `);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  function getFinalMessage() {
    if (isLoading) {
      return `Loading, Please Wait!`;
    }
    if (error) {
      return error;
    }
    if (!response?.data) {
      return " ";
    }
    let message = getLitigatorsResponseMessage(response);

    return message;
  }

  return (
    <h2 key="prefixLabel">
      <span style={{ color: "red" }}>{prefixLabel}</span>
      {getFinalMessage()}
    </h2>
  );
}
export default LitigatorsName;
