const API_ENDPOINT = `https://tcpa-tools-client-api.azurewebsites.net/api`;

// const API_ENDPOINT = `https://localhost:44343/api`;

// const INTERNAL_API_KEY = "6ab004f1-6426-4b91-b413-6d5b47e0c46a";
const SEARCH_PHONE_URL = "SearchPhone";

const BLACKLIST_API_KEY = "AatvtwYBz3s4knmAG66X";

const CLIENT_PORTAL_API_KEY = "fa9fba32-830f-4b41-95d4-b18922e6febe";

const PROXY_URL =
  "https://technationals-cors-anywhere-c044beaa3884.herokuapp.com/";

// const GOOGLE_SHEET_ENDPOINT =
//   "https://script.google.com/macros/s/AKfycbzOOJrcy0TfjC9tRcAlQYdtEsRATipk0dHzf2CoW95qd4SsWBMD53MiuZ7vjbzLI5XwDA/exec";

const ApiEndpointConstants = {
  CLIENT_PORTAL_API_KEY,
  API_ENDPOINT,
  // INTERNAL_API_KEY,
  INTERNAL_LITIGATORS_API_ENDPOINT: `${API_ENDPOINT}/${SEARCH_PHONE_URL}/Litigators/`,
  INTERNAL_LITIGATOR_NAME_API_ENDPOINT: `${API_ENDPOINT}/${SEARCH_PHONE_URL}/NameSearch/`,
  UI_SETTINGS_API_ENDPOINT: `${API_ENDPOINT}/clients/ui-settings`,
  BLACKLIST_API_ENDPOINT: `${PROXY_URL}https://api.blacklistalliance.net/lookup?key=${BLACKLIST_API_KEY}&ver=v3&resp=json&phone=`,
  INTERNAL_VENDOR_DATA_ENDPOINT: `${API_ENDPOINT}/${SEARCH_PHONE_URL}/vendor`,
  INTERNAL_API_ENDPOINT: `${API_ENDPOINT}/${SEARCH_PHONE_URL}/phone/`,
  INTERNAL_EXT_API_ENDPOINT: `${API_ENDPOINT}/${SEARCH_PHONE_URL}/phone/ext/`,
  LITIGATORS_API_ENDPOINT: `${PROXY_URL}https://api.tcpalitigatorlist.com/scrub/phone/`,
  LITIGATORS_NAME_API_ENDPOINT: `${PROXY_URL}https://api.tcpalitigatorlist.com/scrub/name/tcpa/`,

  LITIGATORS_API_USER: "tcpa_OUNZ3ABOc7",
  LITIGATORS_API_PASSWORD: "mK70 kp6l x27j itTe MhBM SDAa",

  DNSCRUB_API_ENDPOINT: `https://www.dncscrub.com/app/main/rpc/scrub?`,
  DNSCRUB_API_KEY: "061031627F2A410897B8A6ED4B5BDCA37F94E34D869D",
  DNSCRUB_VERSION: "5",
  DNSCRUB_OUTPUT: "json",
};

export default ApiEndpointConstants;
