import { useEffect, useState } from "react";

function ResponseMessageRenderer({
  prefixLabel,
  responseKey,
  response,
  messageCallback,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState(<>"Loading, Please Wait!"</>);

  useEffect(() => {
    if (response && responseKey) {
      setIsLoading(true);
      try {
        const responseData = response[responseKey];

        if (messageCallback) {
          const val = messageCallback(responseData);
          setMessage(val);
        } else {
          setMessage(`${responseKey}: ${JSON.stringify(responseData)}`);
        }
      } catch (error) {
        setMessage(`Error: Unable to process response for ${responseKey}`);
      } finally {
        setIsLoading(false);
      }
    }
  }, [response, responseKey, messageCallback]);

  return (
    <h2>
      <span style={{ color: "red" }}>{prefixLabel}</span>
      {isLoading ? `Loading, Please Wait!` : message}
    </h2>
  );
}

export default ResponseMessageRenderer;
