import axios from "axios";

export function handleApiError(
  error,
  defaultMessage = "An error occurred while processing your request."
) {
  if (axios.isAxiosError(error)) {
    const status = error.response?.status;
    const title = error.response?.data?.title;
    // const detail = error.response?.data?.detail;

    // const message = `(Status: ${status || "Unknown"}) ${title}  - ${
    //   detail || "No additional information available."
    // }`;

    const message = `(StatusCode: ${status || "Unknown"}) ${title}`;

    return message;
  } else {
    return defaultMessage;
  }
}
