import axios from "axios";
import ApiEndpointConstants from "../../constants/ApiEndpointConstants";
import config from "../../config/config";

export async function getInternalDbResponse(values) {
  let url = ApiEndpointConstants.INTERNAL_API_ENDPOINT + values;
  const response = await axios.get(`${url}`, {
    headers: {
      "Access-Control-Allow-Origin": "no-cors",
      ApiKey: config.API_KEY,
    },
  });
  return response.data;
}
