import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../config/config";

function GenericSearch({
  prefixLabel,
  searchedValue,
  apiEndpoint,
  messageCallback,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState(<>"Loading, Please Wait!"</>);

  async function fetchData() {
    try {
      let url = apiEndpoint + searchedValue;

      const response = await axios.get(`${url}`, {
        headers: {
          "Access-Control-Allow-Origin": "no-cors",
          ApiKey: config.API_KEY,
        },
      });
      if (messageCallback) {
        const val = messageCallback(response);

        setMessage(val);
      } else {
        setMessage(`${searchedValue} is ${JSON.stringify(response.data)}`);
      }
    } catch (error) {
      setMessage(`Error : ${JSON.stringify(error.data)} `);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValue]);

  return (
    <h2>
      <span style={{ color: "red" }}>{prefixLabel}</span>
      {isLoading ? `Loading, Please Wait!` : message}
    </h2>
  );
}
export default GenericSearch;
